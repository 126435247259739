@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #F5F4F0 !important;
    font-family: Atlas Grotesk,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
}

.rotations:hover::after {
 
    animation: rotateBack 0.3s ease forwards; /* Smooth transition back */
}
.rotations:hover{

    transition: transform 0.3s; 
    animation: rotateY 2s linear infinite;
}

.hover\:shake:hover {
  animation: shake 1s ease-in-out; /* Adjust the animation duration as needed */
  animation-iteration-count: 1; /* Play the animation once */
}


@keyframes rotateY {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg); /* Rotate 360 degrees over the animation duration */
    }
}

@keyframes rotateBack {
    to {
        transform: rotateY(0deg); /* Rotate back to 0 degrees */
    }

}
@keyframes shake {
    0% { transform: translateX(0); }
    10% { transform: translateX(-3px); }
    20% { transform: translateX(3px); }
    30% { transform: translateX(-3px); }
    40% { transform: translateX(3px); }
    50% { transform: translateX(-3px); }
    60% { transform: translateX(3px); }
    70% { transform: translateX(0px); }
    80% { transform: translateX(0px); }
    90% { transform: translateX(0px); }
    100% { transform: translateX(0); }
  }
  
  