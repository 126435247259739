.plus-icon, .negative-icon{
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 0;
    text-align: center;
    display: flex;
    color: #fff;
    font-family: Atlas Grotesk,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #1e1e1e;
}